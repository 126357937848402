
export default {
  data() {
    return {
      types: [],
      legalPages: {
        privacy: null,
        terms: null,
        price: null,
        faq: null,
        help: null,
        affiliate: null,
        travel: null,
        refer: null,
        lawyers: null
      }
    };
  },
  props: ['hideLink'],
  computed: {
    availableLocales() {
      return this.$i18n.locales.filter(i => i.code !== this.$i18n.locale);
    }
  },
  async fetch() {
    const codes = [
      { key: 'privacy', code: 'privacy' },
      { key: 'terms', code: 'terms-conditions' },
      { key: 'price', code: 'price-payment-policy' },
      { key: 'faq', code: 'faq' },
      { key: 'help', code: 'help' },
      { key: 'affiliate', code: 'affiliate' },
      { key: 'travel', code: 'travel-agency' },
      { key: 'refer', code: 'refer-friend' },
      { key: 'lawyers', code: 'partners-lawyers' }
    ];

    const [types, ...pages] = await Promise.all([
      this.$apiClaimTypes.all(),
      ...codes.map(({ code }) => this.$apiPages.getByCode(code))
    ]);

    this.types = types;

    this.legalPages = codes.reduce((acc, { key }, index) => {
      acc[key] = pages[index];
      return acc;
    }, {});
  }
};
