export default ({ app }, inject) => {
    if (process.server && process.env.NODE_ENV === 'production' && !global.apiClaimTypesCache) {
        global.apiClaimTypesCache = {};
    }

    const clientCache = {};

    const getCacheKey = () => {
        const market = app.i18n?.localeProperties?.market || 'default';
        const language = app.i18n?.localeProperties?.code || 'default';
        return `${language}-${market}`;
    };

    const load = async () => {
        const cacheKey = getCacheKey();

        if (process.server && process.env.NODE_ENV === 'production' && global.apiClaimTypesCache[cacheKey] && global.apiClaimTypesCache[cacheKey].expires > Date.now()) {
            return global.apiClaimTypesCache[cacheKey].data;
        }

        if (process.client && clientCache[cacheKey] && clientCache[cacheKey].expires > Date.now()) {
            return clientCache[cacheKey].data;
        }

        const response = await app.$api.contents.claimTypes().catch(err => {
            console.error('Failed to load claim types', err);
            return { data: [] };
        });

        const cacheEntry = {
            data: response.data.codes,
            expires: Date.now() + 10 * 60 * 1000 // 10 minutes
        };

        if (process.server && process.env.NODE_ENV === 'production') {
            global.apiClaimTypesCache[cacheKey] = cacheEntry;
        }

        if (process.client) {
            clientCache[cacheKey] = cacheEntry;
        }

        return response.data.codes;
    };

    inject('apiClaimTypes', {
        load, // short alias for codes only
        all: async () => await load(), // same as load()
        getByCode: async (code) => {
            const types = await load();
            return types.find(type => type.code === code) || null;
        }
    });
};
