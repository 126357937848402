
import Dropdown from '../components/Dropdown.vue';

export default {
  components: {
    Dropdown
  },
  data() {
    return {
      types: [],
      pages: {
        about: null,
        partnership: null,
        help: null
      }
    };
  },
  props: {
    image: Number,
    hideLinks: {
      default: false,
      required: false,
      type: Boolean
    }
  },
  computed: {
    availableLocales() {
      return this.$i18n.locales.filter(i => i.code !== this.$i18n.locale);
    }
  },
  async fetch() {
    const [types, about, partnership, help] = await Promise.all([
      this.$apiClaimTypes.all(),
      this.$apiPages.getByCode('about-us'),
      this.$apiPages.getByCode('partnership'),
      this.$apiPages.getByCode('help')
    ]);

    this.types = types;
    this.pages.about = about;
    this.pages.partnership = partnership;
    this.pages.help = help;
  }
};
