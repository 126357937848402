import Vue from 'vue';
import Dialog from '~/components/Dialog.vue';

const ConfirmDialog = Vue.extend({
    components: {
        Dialog
    },
    props: {
        message: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            open: true
        }
    },
    methods: {
        confirm(result) {
            this.$emit('close', result);
        }
    },
    template: `
      <Dialog :open.sync="open">
          <template v-slot:header>
            Are you sure?
          </template>
          <template v-slot:content>
          </template>
      </Dialog>
    `
});

Vue.prototype.$confirm = function (message) {
    return new Promise((resolve) => {
        const container = document.createElement('div');
        document.body.appendChild(container);

        const dialog = new ConfirmDialog({
            propsData: { message }
        });

        // Handle cleanup
        const onClose = (result) => {
            resolve(result);
            dialog.$off('close', onClose);   // ✅ Remove event listener
            dialog.$destroy();               // ✅ Destroy Vue instance
            if (dialog.$el && dialog.$el.parentNode) {
                dialog.$el.parentNode.removeChild(dialog.$el); // ✅ Remove from DOM
            }
        };

        if (process.client) {
            dialog.$on('close', onClose); // ✅ Add listener
        }
        dialog.$mount(container);
    });
};