import axios from 'axios';

export default ({ app }, inject) => {
    const instance = axios.create({
        baseURL: "https://a.troubleflight.com/api/v3/",
    });

    console.log('AXIOS PLUGIN INIT');

    instance.interceptors.request.use((config) => {
        console.log('AXIOS REQUEST:', config.url);

        if (typeof config.params !== 'object') {
            config.params = {};
        }

        config.params.language = app.i18n?.localeProperties?.code?.substr(0, 2) || 'en';
        config.params.market = app.i18n?.localeProperties?.market || 'gb';
        config.params.currency = app.i18n?.localeProperties?.currency || 'eur';

        if (process.client) {
            config.params.security = (window.outerWidth === 0 && window.outerHeight === 0)
                || !!window.callPhantom
                || !!window._phantom
                || !!window.__phantomas
                || !!window.Buffer
                || !!window.emit
                || !!window.spawn;
        }

        return config;
    });

    inject('axios', instance);
};
