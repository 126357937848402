export default ({ app }, inject) => {
    // Global cache for SSR (safe in production)
    if (process.server && !global.apiPagesCache) {
        global.apiPagesCache = {};
    }

    // Local cache for client-side
    const clientCache = {};

    const getCacheKey = () => {
        const market = app.i18n?.localeProperties?.market || 'default';
        const language = app.i18n?.localeProperties?.code || 'default';
        return `${language}-${market}`;
    };

    const load = async () => {
        const cacheKey = getCacheKey();

        if (process.server && global.apiPagesCache[cacheKey] && global.apiPagesCache[cacheKey].expires > Date.now()) {
            return global.apiPagesCache[cacheKey].data;
        }

        if (process.client && clientCache[cacheKey] && clientCache[cacheKey].expires > Date.now()) {
            return clientCache[cacheKey].data;
        }

        console.log('Loading pages from API');

        const response = await app.$api.contents.codes().catch(err => {
            console.error('Failed to load pages', err.response);
            return { data: [] };
        });

        const cacheEntry = {
            data: response.data.codes,
            expires: Date.now() + 10 * 60 * 1000 // 10 minutes cache
        };

        if (process.server) {
            global.apiPagesCache[cacheKey] = cacheEntry;
        }

        if (process.client) {
            clientCache[cacheKey] = cacheEntry;
        }

        return response.data.codes;
    };

    inject('apiPages', {
        load,
        getByCode: async (code) => {
            const pages = await load();
            return pages.find(page => page.code === code) || null;
        }
    });
};
